import React from "react";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";
import Helper from "../../Helper/helper";
import ContentLoader from "../../Static/contentLoader";
import api from "../../../Environment";
import ReactDOM from 'react-dom'
import io from "socket.io-client";
import { apiConstants } from "../../Constant/constants";
// import Videoplayer from '../Video/VideoPlayer'
import VideoNotReleased from '../../VideoNotReleased';
import VideoMessage from './VideoMessage'
import { createBrowserHistory as createHistory } from "history";
// import Marquee from "react-fast-marquee";
// import VideoJsPlayer from '../Video/VideoJsPlayer'
// import HlsVideoPlayer from '../Video/HlsVideoPlayer'
const socket = apiConstants.socketUrl ? io(apiConstants.socketUrl) : "";
const history = createHistory();

let userId = localStorage.getItem("userId");

let accessToken = localStorage.getItem("accessToken");
class VideoComponent extends Helper {
  state = {
    loadingFirst: true,
    videoDetailsFirst: null,
    onPlayStarted: false,
    videoList: {},
    videoData: null,
    videoId: 0,
    socket: false,
    query: "",
    onSeekPlay: true,
    socketConnection: true,
    videoDuration: 0,
    socketConnected: false,
    styles : null,
    x : -23,
    y : 5,
    i : 1,
    videoFrom:''
  };

  componentDidMount = async ()=> {
    if (this.props.location.state) {
      this.setState({ loadingFirst: false });
    } else {
        const inputData = {
            admin_video_id: this.props.match.params.id,
            skip: 0
        };
        this.setState({ videoFrom: 'mainVideo' });

        await this.onlySingleVideoFirst(inputData);
          this.props.location.state = this.state
          this.redirectStatus(this.state.videoDetailsFirst);
          this.setState({ playButtonClicked: true });
          if(this.state.videoDetailsFirst.pre_booking == 1 || this.state.videoDetailsFirst.should_display_ppv == 1){
            this.setState({ onPlayStarted: true });
          }
      // window.location = "/home";
    }
    setInterval(() => {
      if(this.state.i == 1){
        this.setState({
          x: -23,
          y: 5
        })
      }
      if(this.state.i == 2){
        this.setState({
          x: 1239,
          y: 5
        })
      }
      if(this.state.i == 4){
        this.setState({
          x: 1239,
          y: 584
        })
      }
     
      if(this.state.i == 3){
        this.setState({
          x: -23,
          y: 584
        })
      }
      if(this.state.i == 4){
        this.setState({
          i: 1
        })
      }else{
        this.setState({
          i: this.state.i+1
        })
      }
      // this.setState({
      //   x: 32 + Math.floor(500 * Math.random()),
      //   y: 32 + Math.floor(500 * Math.random()),
      // })
    }, 6000)
  }

  timer = async () => {
    if (this.state.onPlayStarted) {
      await this.socketConnectionfun(userId, accessToken);
    }
  };

  componentWillUnmount() {
    // use intervalId from the state to clear the interval
    clearInterval(this.state.intervalId);
  }

  onCompleteVideo = () => {
    this.addHistory(this.props.location.state.videoDetailsFirst.admin_video_id);
    this.setState({ onPlayStarted: false, socketConnection: false });
    if (this.state.socketConnected) {
      socket.emit("disconnect");
    }
  };

  onVideoPlay = async () => {
    let intervalId = setInterval(this.timer, 3000);

    this.setState({ intervalId: intervalId });

    this.setState({ onPlayStarted: true, socketConnection: true });

    let inputData = {
      admin_video_id: this.props.location.state.videoDetailsFirst
        .admin_video_id,
    };
    await this.onlySingleVideoFirst(inputData);

    this.redirectStatus(this.state.videoDetailsFirst);

    const seekTime = this.state.videoDetailsFirst.seek_time_in_seconds
      ? this.state.videoDetailsFirst.seek_time_in_seconds
      : 0;

    // console.log(seekTime);

    if (this.state.onSeekPlay) {
      this.player.seekTo(parseFloat(seekTime));
    }

    this.setState({ onSeekPlay: false });
  };

  addHistory = (admin_video_id) => {
      this.updateViewCount(admin_video_id);
      api.postMethod("oncomplete/video", { admin_video_id: admin_video_id })
        .then((response) => {
          this.setState({ onPlayStarted: false, socketConnection: false });
      }).catch(function(error) {});
    api.postMethod("addHistory", { admin_video_id: admin_video_id })
      .then((response) => {
        if (response.data.success === true) {
          window.location = "/home";
          this.setState({ onPlayStarted: false, socketConnection: false });
        } else {
        }
      })
      .catch(function(error) {});
  };

  socketConnectionfun = (userId, accessToken) => {
    if (apiConstants.socketUrl) {
      let videoId = this.props.location.state.videoDetailsFirst.admin_video_id;

      socket.on("connect", function() {
        let query = `user_id=` + userId + `&video_id=` + videoId;
      });

      socket.on("connected", function() {
        console.log("Connected");
        this.setState({ socketConnected: true });
      });

      socket.on("disconnect", function() {
        console.log("disconnect");
        this.setState({ socketConnected: false });
      });

      console.log(this.state.videoDuration);

      let videoData = [
        {
          sub_profile_id: localStorage.getItem('active_profile_id'),
          admin_video_id: videoId,
          id: userId,
          token: accessToken,
          duration: this.state.videoDuration,
        },
      ];

      socket.emit("save_continue_watching_video", videoData[0]);
    }
  };

  onPauseVideo = async () => {
    console.log("onPause");
    if (this.state.socketConnected) {
      socket.emit("disconnect");
    }
    clearInterval(this.state.intervalId);
  };

  onVideoTimeUpdate = (duration) => {
    let video_duration = duration.target.currentTime;

    let sec = parseInt(video_duration % 60);
    let min = parseInt((video_duration / 60) % 60);
    let hours = parseInt(video_duration / 3600);

    if (hours > 1) {
      this.setState({ videoDuration: hours + ":" + min + ":" + sec });
    } else {
      this.setState({ videoDuration: min + ":" + sec });
    }
  };

  ref = player => {
    this.player = player
}

  render() {
    let {x, y} = this.state
    const pageType = "videoPage";
    // console.log(this.state.onPlayStarted)
    if (this.state.onPlayStarted) {
      const returnToVideo = this.renderRedirectPage(
        this.state.videoDetailsFirst,
        pageType
      );

      if (returnToVideo != null) {
        return returnToVideo;
      }
    }
    const { loadingFirst } = this.state;
    let mainVideo, videoTitle, videoType, subTitle,trailer,next_admin_video_id,poster,admin_video_id,pre_booking,publish_time,publish_time_convert,should_display_ppv,pre_book_message;

    if (loadingFirst) {
      return <ContentLoader />;
    } else {
      // Check the whether we need to play the trailer or main video

      trailer = 0
      if(this.state.videoFrom =="mainVideo"){

          mainVideo = this.state.videoDetailsFirst.main_video;
    
          subTitle = this.state.videoDetailsFirst.video_subtitle;

          videoTitle = this.state.videoDetailsFirst.title;

          videoType = this.state.videoDetailsFirst.video_type;
          next_admin_video_id = this.state.videoDetailsFirst.next_admin_video_id;
          poster = this.state.videoDetailsFirst.default_image;
          admin_video_id = this.state.videoDetailsFirst.admin_video_id;

          pre_booking = this.state.videoDetailsFirst.pre_booking;
          publish_time = this.state.videoDetailsFirst.publish_time;
          publish_time_convert = this.state.videoDetailsFirst.publish_time_convert;
          pre_book_message = this.state.videoDetailsFirst.pre_book_message_after;
          should_display_ppv = this.state.videoDetailsFirst.should_display_ppv;
      }else{

        if (this.props.location.state.videoFrom != undefined) {
          subTitle = this.props.location.state.videoDetailsFirst.video_subtitle;
  
          if (this.props.location.state.videoFrom == "trailer") {
            mainVideo = this.props.location.state.videoDetailsFirst.resolutions
              .original;
            subTitle = this.props.location.state.videoDetailsFirst
              .trailer_subtitle;
              trailer = 1
          } else {
            mainVideo = this.props.location.state.videoDetailsFirst.resolutions
              .original;
          }
  
          videoTitle = this.props.location.state.videoDetailsFirst.name;
  
          videoType = this.props.location.state.videoDetailsFirst.video_type;
          next_admin_video_id = this.props.location.state.videoDetailsFirst.next_admin_video_id;
          poster = this.props.location.state.videoDetailsFirst.default_image;
          admin_video_id = this.props.location.state.videoDetailsFirst.admin_video_id;
          pre_booking = this.props.location.state.videoDetailsFirst.pre_booking;

          publish_time = this.props.location.state.videoDetailsFirst.publish_time;
          publish_time_convert = this.props.location.state.videoDetailsFirst.publish_time_convert;
          pre_book_message = this.props.location.state.videoDetailsFirst.pre_book_message_after;
          should_display_ppv = this.props.location.state.videoDetailsFirst.should_display_ppv;
          
        } else {
          mainVideo = this.props.location.state.videoDetailsFirst.main_video;
  
          subTitle = this.props.location.state.videoDetailsFirst.video_subtitle;
  
          videoTitle = this.props.location.state.videoDetailsFirst.title;
  
          videoType = this.props.location.state.videoDetailsFirst.video_type;
          next_admin_video_id = this.props.location.state.videoDetailsFirst.next_admin_video_id;
          poster = this.props.location.state.videoDetailsFirst.default_image;
          admin_video_id = this.props.location.state.videoDetailsFirst.admin_video_id;
          pre_booking = this.props.location.state.videoDetailsFirst.pre_booking;

          publish_time = this.props.location.state.videoDetailsFirst.publish_time;
          publish_time_convert = this.props.location.state.videoDetailsFirst.publish_time_convert;
          pre_book_message = this.props.location.state.videoDetailsFirst.pre_book_message_after;
          should_display_ppv = this.props.location.state.videoDetailsFirst.should_display_ppv;
        }  
      }
 
      return (
        <div>
          { pre_booking == 1 ? (
              <VideoNotReleased
                publish_time={publish_time}
                publish_time_convert={publish_time_convert}
                videoTitle={videoTitle}
                poster={poster}
                message={pre_book_message}
              ></VideoNotReleased>
          ):(
          <div className="single-video">

            <VideoMessage
                videoTitle={videoTitle}
                poster={poster}
              ></VideoMessage>
            {/* { videoType == 3 && trailer == 0 ? (
            <VideoJsPlayer   
               poster={poster}
               location    = {this.props.location}
               mainVideo   = {mainVideo}
               subTitle    = {subTitle}
               videoTitle  = {videoTitle}
               videoType   = {videoType}  
              nextAdminVideo = {next_admin_video_id}
              admin_video_id = {admin_video_id}
               
              ></VideoJsPlayer>
            ):(
            <ReactPlayer
              ref={this.ref}
              url={mainVideo}
              controls={true}
              width="100%"
              height="100vh"
              playing={true}
              onStart={this.onload}
              onPause={this.onPauseVideo}
              onPlay={
                this.props.location.state.videoFrom == "trailer"
                  ? ""
                  : this.onVideoPlay
              }
              onEnded={this.onCompleteVideo}
              onTimeUpdate={this.onVideoTimeUpdate.bind(this)}
              light={this.props.location.state.videoDetailsFirst.default_image}
              config={{
                file: {
                  tracks: [
                    {
                      kind: "subtitles",
                      src: subTitle,
                      srcLang: "en",
                      default: true,
                    },
                  ],
                  attributes: {
                    controlsList: "nodownload",
                    crossOrigin: 'anonymous'
                  },
                },
              }}
            />
            )} */}
            <div className="back-arrowsec">
          
           {/* {should_display_ppv == 0 ? (

              <Link  to="/home"  >
                 <img
                  src={window.location.origin + "/assets/img/left-arrow.png"}
                  alt="arrow"
                />
                <img className="image_view" src={poster}></img>
                {videoType == 2 ? (
                  ""
                ) : (
                  <span className="txt-overflow capitalize ml-3">
                    {videoTitle}
                  </span>
                )}
              </Link>
            ):( */}
              <Link  to="/home"  >
                <img
                  src={window.location.origin + "/assets/img/left-arrow.png"}
                  alt="arrow"
                />
                <img className="image_view" src={poster}></img>
                {videoType == 2 ? (
                  ""
                ) : (
                  <span className="txt-overflow capitalize ml-3">
                    {videoTitle}
                  </span>
                )}
              </Link>
            {/* )} */}
              {/* <div className="text_rotate"  style={{
                  transform: `translateX(${x}px) translateY(${y}px)`,
                  transition: 'transform .5s',
                }}>
                  { localStorage.getItem("username")}
              </div> */}
            </div>
          </div>
            )
          }
        </div>
      );
    }
  }
}

export default VideoComponent;