import React,{Fragment} from "react";
import Helper from "../../Helper/helper";
import { withToastManager } from "react-toast-notifications";
import io from "socket.io-client";
import { apiConstants } from "../../Constant/constants";
import api from "../../../Environment";
import ToastDemo from "../../Helper/toaster";
import './CommentBox.css'
import { translate, t } from "react-multi-lang";

class CommentBox extends Helper {
    
    state = {
        showComments: true,
        data:{
          comment:'',
          admin_video_id : 0,
          parent_id : 0,
        },
        comment : '',
        admin_video_id : 0,
        parent_id : 0,
        review_id : 0,
        likeReponse: null,
        likeApiCall : null,
        postComment:true,
        postReplayComment:false,
        postReportComment:false,
    };
    handleSubmit(event) {
      event.preventDefault(); 
      this.state.data.admin_video_id = this.props.videoDetailsFirst.admin_video_id
      this.state.data.parent_id = this.state.parent_id
      this.state.data.review_id = this.state.review_id

      if(this.state.postComment || this.state.postReplayComment){
        api.postMethod("reviews/add-review", this.state.data).then((response) => {
          if (response.data.success === true) {
                ToastDemo(this.props.toastManager, response.data.message, "success");
                this.state.data.comment = ''
                this.state.data.parent_id = 0
                this.setState({
                  comment: '',
                  parent_id: 0,
                  postComment:true,
                  postReplayComment:false,
                  postReportComment:false,
                  review_id : 0,
              });
              // this.getVideoReviews();
            } else {
              ToastDemo(
              this.props.toastManager,
              response.data.error_messages,
              "error"
            )
          }
        });
      }
      if(this.state.postReportComment){
        api.postMethod("reviews/add-report-abuse", this.state.data).then((response) => {
          if (response.data.success === true) {
                ToastDemo(this.props.toastManager, response.data.message, "success");
                this.state.data.comment = ''
                this.state.data.parent_id = 0
                this.state.data.review_id = 0
                this.setState({
                  comment: '',
                  parent_id: 0,
                  postComment:true,
                  postReplayComment:false,
                  postReportComment:false,
                  review_id : 0,
              });
            } else {
              ToastDemo(
              this.props.toastManager,
              response.data.error_messages,
              "error"
            )
          }
        });
      }
    }
  
    _getCommentsTitle(commentCount) {
      if (commentCount === 0) {
        return 'No comments yet';
      } else if (commentCount === 1) {
        return "1 comment";
      } else {
        return `${commentCount} comments`;
      }
    }

    handleOnClickLikeDisLike(review,likeStatus){
      // event.preventDefault();
      const data = {
        admin_video_id : review.admin_video_id,
        review_id      : review.review_id,
        likeDislike    : likeStatus
      }

      api.postMethod("reviews/like-dislike", data).then((response) => {
        if (response.data.success === true) {
          if(response.data.data.mode == 'dislike'){
              ToastDemo(this.props.toastManager, "Disliked this Video!", "success");
          } else {
              ToastDemo(this.props.toastManager, "You liked this Video!", "success");
          }
          this.setState({
            likeReponse: response.data.data,
            likeApiCall: true,
          });
        } else {
          ToastDemo(
            this.props.toastManager,
            response.data.error_messages,
            "error"
          );
        }
      });
    };
    handleOnClickReply(review){
      this.state.data.comment = ''
      this.setState({
        postComment : false,
        postReplayComment : true,
        postReportComment : false,
        parent_id : review.review_id,
      });
    };
    handleOnClickReport(reply){
      this.state.data.comment = ''

      this.setState({
        postComment:false,
        postReplayComment:false,
        postReportComment:true,
        review_id : reply.review_id,
      });
    };
    render () {
      const { t } = this.props;

      const { videoReviewDetails,loadingReviews,videoDetailsFirst ,reviewCount} = this.props;
      const { 
        data,
        likeReponse,
        likeApiCall 
      } = this.state;
      return(
        <div className="slider-topbottom-spacing">
          <div className="float half">
            <div className="comment-box">
              <h2>Join the Discussion!</h2>
                <form className="comment-form" onSubmit={this.handleSubmit.bind(this)}>
                  <div className="comment-form-fields">
                    <textarea style={{
                      width:'100%',
                      border: '1px solid #404040'
                      }} rows="8" placeholder="Comment" onChange={this.handleChange} required name="comment"  value={this.state.data.comment}></textarea>
                  </div>
                  <div className="comment-form-actions">                 
                    <button type="submit">
                     
                      { this.state.postComment ? ('Post Comment'):('') }
                      { this.state.postReplayComment ? ('Reply Comment'):('') }
                      { this.state.postReportComment ? ('Report Comment'):('') }
                      </button>
                  </div>
                </form>
              <h4 className="comment-count">
                {loadingReviews ? (
                  ""
                ):(
                   this._getCommentsTitle(reviewCount)
                 )} 
              </h4>
            </div> 
          </div>
          <div className="float half">
                {loadingReviews ? (
                  ""
                ):(
                  videoReviewDetails.length > 0 ? (
                  <div className="comment-list">
                    <div className="comment-box">
                      <div className="comment-area-box">
                        <ul className="comment-tree">
                            {this.props.videoReviewDetails.map(comment => (
                              <Fragment>
                              <li className="level1">
                                  <div className="comment-boxs">
                                    <div className="comment-content">
                                        <h4>
                                          {comment.name}                           
                                        </h4>
                                        <span><i className="fa fa-calendar"></i> {comment.date}</span>
                                        <p>{comment.comment}</p>
                                        <h4>
                                          <LikeDislike comment={comment} toastManager={this.props.toastManager}/>
                                            <a onClick={this.handleOnClickReply.bind(this, comment)} id={comment.review_id} className="replyBtn" >
                                              <i className="far fa-comment"></i>Reply
                                            </a>
                                            <a  onClick={this.handleOnClickReport.bind(this, comment)} className="reportAbuseBtn" >Report abuse</a>
                                        </h4>
                                      </div>
                                    </div>
                                </li>
                                {comment.reply_reviews.map(replay => (
                                  <li className="level2">
                                  <div className="comment-boxs">
                                    <div className="comment-content">
                                        <h4>
                                          {replay.name}                           
                                        </h4>
                                        <span><i className="fa fa-calendar"></i> {replay.date}</span>
                                        <p>{replay.comment}</p>
                                        <h4>
                                            <LikeDislike comment={replay} toastManager={this.props.toastManager}/>
                                            <a  onClick={this.handleOnClickReport.bind(this, replay)} className="reportAbuseBtn" >Report abuse</a>
                                        </h4>
                                      </div>
                                    </div>
                                  </li>
                                ))
                              }
                              </Fragment>
                              )
                            )}
                        </ul>
                      </div>
                    </div>
                  </div>
                  ):('')
                )}
          </div>
        </div>  
      );
    } 
  } 

class LikeDislike extends Helper {
      state={
        likeReponse: null,
      }
  render () {
    const { 
      likeReponse 
    } = this.state;
    return(
      <Fragment>
        <a className="disAgreeBtn comment-footer-delete"
          onClick={this.handleOnClickLikeDisLike.bind(this, this.props.comment,0)}>
              <i className="far fa-thumbs-down"></i>DisAgree 
              <span className="disAgreeBtn">
              [
                {likeReponse ? likeReponse.dislike_count : this.props.comment.dislikes}
              ]
              </span>
          </a> 
          <a className="agreeBtn"
          onClick={this.handleOnClickLikeDisLike.bind(this, this.props.comment,1)}>
              <i className="far fa-thumbs-up"></i>Agree 
              <span className="agreeBtn">
                [
                  {likeReponse ? likeReponse.like_count : this.props.comment.likes}
                ]
              </span>
          </a>
          </Fragment>
    );
  }
  handleOnClickLikeDisLike(review,likeStatus){
    // event.preventDefault();
    const data = {
      admin_video_id : review.admin_video_id,
      review_id      : review.review_id,
      likeDislike    : likeStatus
    }

    api.postMethod("reviews/like-dislike", data).then((response) => {
      if (response.data.success === true) {
        if(response.data.data.mode == 'dislike'){
            ToastDemo(this.props.toastManager, "You disliked this comment", "success");
        } else {
            ToastDemo(this.props.toastManager, "You liked this comment", "success");
        }
        this.setState({
          likeReponse: response.data.data,
          likeApiCall: true,
        });
      } else {
        ToastDemo(
          this.props.toastManager,
          response.data.error_messages,
          "error"
        );
      }
    });
  };
}
  export default withToastManager(translate(CommentBox));