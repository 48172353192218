import { Component } from "react";
import { withToastManager } from "react-toast-notifications";
import ToastDemo from "../Helper/toaster";
import api from "../../Environment";

import { translate } from "react-multi-lang";

class Logout extends Component {
    componentDidMount() {
        api.postMethod("logout",[])
      .then((response) => {
        if (response.data.success) {
          ToastDemo(this.props.toastManager,this.props.t("logout_success"), "success");
        } else {
          ToastDemo(
            this.props.toastManager,
            response.data.error_messages,
            "error"
          );
          this.setState({
            loadingContent: null,
            buttonDisable: false,
          });
        }
        localStorage.removeItem("accessToken");
        localStorage.removeItem("userId");
        localStorage.removeItem("userLoginStatus");
        localStorage.removeItem("push_status");
        localStorage.removeItem("active_profile_id");
        localStorage.removeItem("userType");
        localStorage.removeItem("username");
        localStorage.removeItem("email");
        localStorage.removeItem("mobile");
        localStorage.removeItem("active_profile_image");
        localStorage.removeItem("active_profile_name");
        this.props.history.push("/login");
      })
      .catch((error) => {
        ToastDemo(this.props.toastManager, error, "error");
        this.setState({ loadingContent: null, buttonDisable: false });
      });
    }
    render() {
        return null;
    }
}

export default withToastManager(translate(Logout));
