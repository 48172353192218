import React, { Component } from "react";

// import Slider from "react-slick";
import cx from "classnames";
import { Link } from "react-router-dom";
import api from "../../Environment";
import Slider from "../SliderView/MainSlider";

const $ = window.$;

class SearchComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: this.props.data,
      serachKey : '',
      maindata: null,
      loadingSuggesstions : true
    };
  }

  showSliderContent() {
    $(".slider-content").css("display", "block");
  }

  closeSliderContent() {
    $(".slider-content").css("display", "none");
  }

  componentDidMount() {
    $(window).load(function() {
      $(".placeholder").each(function() {
        var imagex = $(this);
        var imgOriginal = imagex.data("src");
        var imgOriginalSet = imagex.data("srcset");
        $(imagex).attr("src", imgOriginal);
        $(imagex).attr("srcset", imgOriginalSet);
      });
    });

    var scaling = 1.5;

    var windowWidth = $("body").width();

    var videoWidth = $(".sliderthumb").outerWidth();

    var videoHeight = Math.round(videoWidth / (16 / 9));

    var videoSecHeight = videoHeight * scaling;

    var videoHeightDiff = videoSecHeight - videoHeight;

    var mobileVideosecHeight = videoSecHeight - videoHeightDiff / 2;

    $(".mylist-slider").height(videoSecHeight);

    $(".home-slider .sliderthumb").height(videoHeight);

    $(".home-slider .sliderthumb").css("margin-top", videoHeightDiff / 2);

    if (windowWidth > 991) {
      $(".home-slider .sliderthumb").mouseover(function() {
        $(this).css("width", videoWidth * scaling);

        $(this).css("height", videoHeight * scaling);

        $(this).css("z-index", 100);

        $(this).css("margin-top", 0);
      });

      $(".home-slider .sliderthumb").mouseout(function() {
        $(this).css("width", videoWidth * 1);

        $(this).css("height", videoHeight * 1);

        $(this).css("z-index", 0);

        $(this).css("margin-top", videoHeightDiff / 2);
      });
    } else {
      $(".home-slider").height(mobileVideosecHeight);
    }
  }
  componentDidMount() {
      const urlType = this.props.match.params.search;
      api.postMethod("search_videos", { key: urlType })
      .then(response => {
          if (response.data.success === true) {
              console.log("REsponse", response.data);
              this.setState({
                  serachKey:urlType,
                  maindata: response.data,
                  loadingSuggesstions: false
              });
              if (response.data.data.length <= 0) {
                  // this.setState({ displaySuggesstion: "none"});
              }
          } else {
          }
      })
      .catch(function (error) { });
  }
  render() {
    var episodeSlider = {
      dots: false,
      arrow: true,
      slidesToShow: 4,
      slidesToScroll: 4,
      infinite: false
    };

    var trailerSlider = {
      dots: false,
      arrow: true,
      infinite: false,
      slidesToShow: 4,
      slidesToScroll: 1
    };

    var morelikeSlider = {
      dots: false,
      arrow: true,
      infinite: false,
      slidesToShow: 4,
      slidesToScroll: 1
    };

    const { t } = this.props;

    const { maindata,serachKey,loadingSuggesstions } = this.state;
    return (
      <div>
        <div className="slider-topbottom-spacing">
        <div><h2>Showing all results for <span>{ serachKey }</span></h2></div>
        
        {loadingSuggesstions ? (
                 "loading"
                    ) : maindata.data.length > 0 ? (
                        // maindata.data.map(
                        //     (
                        //       movie,
                        //         index
                        //     ) => (
                        <Slider>
                        {maindata.data.map((movie) => (
                          <Slider.Item movie={movie} key={movie.admin_video_id}>
                            item1
                          </Slider.Item>
                        ))}
                      </Slider>
                                    
                      //   )
                      // )
                  ) : (
                  <li
                      className=""
                      key="suggestion-no-result"
                  >
                      <span>
                          {(
                              "no_results_found"
                          )}
                      </span>
                  </li>
              )}
        </div>
      </div>
    );
  }
}

export default SearchComponent;
