import React, { Component } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { translate } from "react-multi-lang";
import ReactPlayer from "react-player";

class VideoTrailer extends Component {
    constructor () {
        super();
        this.state = {
          showModal: false
        };
        
        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
      }
      
      handleOpenModal () {
        this.setState({ showModal: true });
      }
      
      handleCloseModal () {
        this.setState({ showModal: false });
      };
      ref = player => {
        this.player = player
    }
    render() {
        const { t } = this.props;
        const trailerDetails = this.props.trailer;
        const videoDetailsFirst = this.props.videoDetailsFirst;

        // let slidesToShowCount = 1;

        // if (trailerDetails.length > 3) {
        //     slidesToShowCount = 4;
        // } else {
        //     slidesToShowCount = trailerDetails.length;
        // }
        var trailerSlider = {
            dots: false,
            arrow: true,
            slidesToShow: 4,
            slidesToScroll: 1,
            infinite: false
        };

        return (
            <div className="slider-topbottom-spacing pl-0 pr-0 slider-overlay">
                <div className="pr-4per pl-4per">
                    <h1 className="banner_video_title">
                        {t("trailer_and_more")}
                    </h1>
                </div>
                <div>
                    <Slider
                        {...trailerSlider}
                        className="more-like-slider slider"
                    >
                        {trailerDetails.map(trailer => (
                            <div key={Math.random()}>
                             
                                {!this.state.showModal ? (
                                       <React.Fragment>
                                <div className="relative">
                                    <img
                                        className="trailers-img placeholder"
                                        alt="episode-img"
                                        src={trailer.default_image}
                                        data-src="assets/img/thumb8.jpg"
                                        srcSet={
                                            trailer.default_image +
                                            " 1x," +
                                            trailer.default_image +
                                            " 1.5x," +
                                            trailer.default_image +
                                            " 2x"
                                        }
                                    />
                                    <div className="trailers-img-overlay">
                                        {/* <Link
                                            to={{
                                                pathname: `/video/${trailer.name}`,
                                                state: {
                                                    videoDetailsFirst: trailer,
                                                    videoFrom: "trailer"
                                                }
                                            }}
                                        > */}
                                            <div className="thumbslider-outline" onMouseOver={this.handleOpenModal}
                                            onMouseOut={this.handleCloseModal}
                                            >
                                                <i className="fas fa-play" />
                                            </div>
                                        {/* </Link> */}
                                    </div>
                                </div>
                                <div className="episode-content">
                                    <h4 className="episode-content-head">
                                        {trailer.name}
                                    </h4>
                                </div>
                                    </React.Fragment>
                                ):(
                                <ReactPlayer
                                ref={this.ref}

                                    onMouseOut={this.handleCloseModal}
                                    // onMouseOver={this.onVideoPlay}
                                    url={videoDetailsFirst.trailer_video}
                                    height="50vh"
                                    autoPlay
                                    playing={true}
                                    controls={true}
                                    light={videoDetailsFirst.default_image}
                                    />
                                )}
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        );
    }
}

export default translate(VideoTrailer);
