import React from 'react';
import { Link } from "react-router-dom";
function NotFoundComponent (){
	return <div>
        <h1>404 - Sorry this page is not found</h1>
        <Link to="/home">Go Back</Link>
    </div>
    
}

export default NotFoundComponent;