import React, {Component} from 'react';

import {Link} from 'react-router-dom';
// import Marquee from "react-fast-marquee";
class VideoNotReleased extends Component {
    markup (val) {
        return { __html: val }
    }
    
    render() {
      
        return (
            <div>
                <div className="banner-sec banner-secc">
                  <div className="banner-home-anothertype relative">
                        <img className="banner_right_img" src={this.props.poster}
                        srcSet={
                            this.props.poster +
                            " 1x," +
                            this.props.poster +
                            " 1.5x," +
                            this.props.poster +
                            " 2x"
                        } alt="banner img" />
                    <div className="banner_overlay">
                      <div className="width-100">
                          <h1 className="banner_video_title">{this.props.videoTitle}</h1>
                          {/* <Marquee gradientWidth={0} speed={40}> */}
                          <h2 className="banner_video_text" style={{
                              'max-height': '11.3em'
                          }}>
                          <div className="banner-btn-sec" dangerouslySetInnerHTML={this.markup(this.props.message)} />
                          </h2>
                          {/* </Marquee> */}
                          <div className="banner-btn-sec" style={{ textAlign:'center'}}>
                              <Link to="/home" className="btn btn-grey">&#8592;
                              Go TO Home Page</Link>   
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
        );

    }

}


export default VideoNotReleased;

