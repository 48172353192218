import React, { Component } from "react";
import api from "../../../Environment";
import { Link } from "react-router-dom";

import {
    setTranslations,
    setDefaultLanguage,
    setLanguage,
    translate,
    getLanguage
} from "react-multi-lang";
import en from "../../translation/en.json";
import pt from "../../translation/pt.json";

import configuration from "react-global-configuration";

const $ = window.$;

class VideoMessage extends Component {
    markup (val) {
        return { __html: val }
    }
    render() {
        const { t } = this.props;
      
        return (
            <div>
                <div className="banner-sec banner-secc">
                  <div className="banner-home-anothertype card_image">
                        <img className="banner_right_img" src={this.props.poster}
                        srcSet={
                            this.props.poster +
                            " 1x," +
                            this.props.poster +
                            " 1.5x," +
                            this.props.poster +
                            " 2x"
                        } alt="banner img" />
                    <div className="banner_overlay">
                      <div className="main2">
                         
                            <div className="card">
 
                    <div className="card_content">
                    <div className="box_space">
                            <p className="card_text box-text-left">
                                    Please Download our App to enjoy the content.   
                            </p>
                            <a className="box-margin-left"
                                href={configuration.get(
                                    "configData.appstore"
                                )}
                                target="_blank"
                            >
                                <img
                                    src="/assets/img/app-store.png"
                                    className="app-img"
                                    alt="Playstore"
                                />
                            </a>
                            <a
                                href={configuration.get(
                                    "configData.playstore"
                                )}
                                target="_blank"
                            >
                                <img
                                    src="/assets/img/play-store.png"
                                    className="app-img"
                                    alt="Playstore"
                                />
                            </a>
                            <a
                                href='https://play.google.com/store/apps/details?id=biz.atconline.localwoodtv'
                                target="_blank"
                            >
                                <img
                                    src="/assets/img/an.png"
                                    className="app-img"
                                    alt="androidtv"
                                />
                            </a>
                            {/* <a
                                href={configuration.get(
                                    "configData.playstore"
                                )}
                                target="_blank"
                            >
                                <img
                                    src="/assets/img/firetvstick.png"
                                    className="app-img"
                                    alt="firetv"
                                />
                            </a> */}
                           </div>
                        </div>
                    </div>
                            {/* <h3>{this.props.videoTitle}</h3> */}
                      </div>
                    </div>
                  </div>
                </div>
            </div>
        );
    }
}


export default translate(VideoMessage);

